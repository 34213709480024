<template>
    <div class="MyTimelineItem">
        <div class="MyTimelineItem__tail"></div>
        <div class="MyTimelineItem__node">{{ index }}</div>
        <div class="MyTimelineItem__wrapper">
            <div class="MyTimelineItem__wrapper--title">{{ title }}</div>
            <div class="MyTimelineItem__wrapper--content" v-if="content || $slots.default">
                <slot>{{ content }}</slot>
                <div class="explain">{{ explain }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MyTimelineItem",
        props: {
            title: String,
            content: String,
            explain: String,
            index: Number
        },
        mounted() {
            window.console.log(this.$el);
        }
    }
</script>

<style lang="scss" scoped>
    .MyTimelineItem {
        position: relative;
        height: 100%;
        font-size: 14px;
        &__tail {
            position: absolute;
            left: 14px;
            border-left: 2px dashed #e4e7ed;
            height: calc(100% - 62px);
            top: 46px;
        }
        &__node {
            color: #FFFFFF;
            position: absolute;
            background: #1677FF;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
        }
        &__wrapper {
            position: relative;
            padding-left: 40px;
            &--title {
                height: 30px;
                line-height: 30px;
                color: #1677FF;
                font-weight: 600;
            }
            &--content {
                padding: 16px 0;
                word-break: break-word;
                .explain {
                    color: #999999;
                    line-height: 30px;
                }
            }
        }
    }
</style>
